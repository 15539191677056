<template>
  <div>
    <router-link
      :to="{ name: route }"
      class="p-2 mt-1 text-left d-flex flex-row align-items-center rounded cursor-pointer"
      :active-class="activeClass"
      :class="hoverClass"
      tag="div"
    >
      <div v-if="icon" class="mr-md-2">
        <BaseIcon :name="icon"></BaseIcon>
      </div>
      <div class="d-none d-md-block mb-1">
        <small>{{ text }}</small>
      </div>
    </router-link>
  </div>
</template>
<script>
import BaseIcon from "@/components/bases/Icon.vue";

export default {
  components: { BaseIcon },
  props: {
    route: {
      Type: String,
      Requierd: true
    },
    text: {
      Type: String,
      Default: "Texte"
    },
    icon: {
      Type: String,
      Default: "box"
    },
    activeTab: {
      Type: String,
      Default: true
    }
  },
  computed: {
    activeClass: function() {
      if (!this.$store.getters["colors/darkMode"]) {
        return "text-danger";
      } else {
        return "text-warning";
      }
    },
    hoverClass: function() {
      if (!this.$store.getters["isDarkMode"]) {
        return "cursor-hover-light";
      } else {
        return "cursor-hover-dark";
      }
    }
  }
};
</script>
<style lang="css">
.stroke-red {
  stroke: #dc3545;
}

.stroke-grey {
  stroke: #d3d3d3;
}
</style>