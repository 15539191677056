<template>
  <router-link :to="link" class="text-center cursor-pointer" tag="div" v-tooltip="text">
    <ButtonCircle name="x" :size="size" :color="color" @click="$router.push(link)"></ButtonCircle>
  </router-link>
</template>
<script>
//import BaseIcon from "@/components/bases/Icon.vue";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";
export default {
  components: { ButtonCircle },
  data() {
    return {};
  },
  props: {
    text: {
      type: String,
      required: false,
    },
    link: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
      default: "30",
    },
  },
  mounted() {},
  computed: {
    color: function () {
      if (this.$store.getters["colors/darkMode"]) {
        return "white";
      } else {
        return "black";
      }
    },
  },
};
</script>
<style lang="css"></style>
