<template>
  <div class="d-block">
    <div
      class="d-flex flex-column flex-md-row justify-content-start align-items-center p-2 p-md-4 rounded-top border-bottom"
      :class="qwarkBorder"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  methods: {},
  props: {},
  computed: {
    qwarkBorder: function() {
      if (!this.$store.getters["colors/darkMode"]) {
        return "qwark-border-light";
      } else {
        return "qwark-border-dark";
      }
    }
  }
};
</script>
<style lang="css"></style>
