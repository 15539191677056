<template>
  <div class="d-none d-md-block">
    <div v-if="contrats">
      <div v-for="contrat in contrats" :key="contrat.id">
        <contrat-viewer :contrat="[contrat]" :mode="2"></contrat-viewer>
      </div>
    </div>
    <div class="text-muted" v-else>
      <small>Aucun contrat enregsitré</small>
    </div>
  </div>
</template>
<script>
import ContratViewer from "@/components/collaborateurs/viewers/ContratViewer.vue";
export default {
  components: {
    ContratViewer,
  },
  props: {
    collaborateur: {
      Type: Object,
      Requierd: true,
      Default: {},
    },
  },
  computed: {
    contrats() {
      return this.collaborateur.collaborateur_contrats;
    },
  },
};
</script>
<style lang="css"></style>
