export default {
  methods: {
    dateSimple(_date) {
      if (_date == null) return "aucune date";
      return this.$moment(_date).format("DD/MM/YYYY");
    },
    dateTimeSimple(_date) {
      if (_date == null) return "aucune date";
      return this.$moment(_date).format("DD/MM/YYYY HH:mm");
    },
    dateMois(_date) {
      return this.$moment(_date).format("YYYY-MM");
    },
    dateMoisText(_date) {
      return this.$moment(_date).format("MMMM YYYY");
    },
    dateMoisSystem(_date) {
      return this.$moment(_date)
        .startOf("month")
        .format("YYYY-MM-DD");
    },
    fromNow(_date) {
      return this.$moment(_date)
        .locale("fr")
        .fromNow();
    },
    dateAndFromNow(_date) {
      return (
        this.$moment(_date).format("DD/MM/YYYY") +
        " - " +
        this.$moment(_date)
          .locale("fr")
          .fromNow()
      );
    }
  }
};
