<template>
  <div class>
    <h2 class="mb-1 d-none d-md-block">
      <strong>
        {{ collaborateur.collaborateur_nom }}
        {{ collaborateur.collaborateur_prenom }}
      </strong>
    </h2>
    <h4 class="m-0 d-md-none">
      <strong>
        {{ collaborateur.collaborateur_nom }}
        {{ collaborateur.collaborateur_prenom }}
      </strong>
    </h4>
    <div class>
      <div v-if="collaborateur.collaborateur_date_naissance">
        <small>
          <div class="d-flex align-items-center">
            <BaseIcon class="mr-1" name="gift" width="15" height="15"></BaseIcon>
            <span>
              {{
              $moment().diff(collaborateur.collaborateur_date_naissance, "years")
              }}
              ans - Né(e) le :
              {{
              $moment(collaborateur.collaborateur_date_naissance).format(
              "DD/MM/YYYY"
              )
              }}
            </span>
          </div>
        </small>
      </div>
      <div v-if="collaborateur.collaborateur_rinpp">
        <small>
          <div class="d-flex align-items-center">
            <BaseIcon class="mr-1" name="info" width="15" height="15"></BaseIcon>
            <span>N°RINPP : {{ collaborateur.collaborateur_rinpp }}</span>
          </div>
        </small>
      </div>
      <div class="d-flex flex-column">
        <div class="mr-3" v-if="collaborateur.collaborateur_email_pro">
          <small>
            <a
              class="d-flex align-items-center"
              :href="'mailto:' + collaborateur.collaborateur_email_pro"
            >
              <BaseIcon class="mr-1" name="mail" width="15" height="15"></BaseIcon>
              <span>{{ collaborateur.collaborateur_email_pro }}</span>
            </a>
          </small>
        </div>
        <div class="mr-3" v-if="collaborateur.collaborateur_tel_1">
          <small>
            <a class="d-flex align-items-center" :href="'tel:' + collaborateur.collaborateur_tel_1">
              <BaseIcon class="mr-1" name="phone" width="15" height="15"></BaseIcon>
              <span>{{ collaborateur.collaborateur_tel_1 }}</span>
            </a>
          </small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseIcon from "@/components/bases/Icon.vue";
export default {
  components: { BaseIcon },
  props: {
    collaborateur: {
      Type: Object,
      Requierd: true,
      Default: {}
    }
  }
};
</script>
<style lang="css"></style>
