var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bloc-simple',{staticClass:"h-100"},[(_vm.loadingCollab || _vm.loadingData)?_c('div',{staticClass:"d-flex align-items-center"},[_c('loader',{staticClass:"m-5"}),_c('h2',{staticClass:"m-0"},[_vm._v("Chargement...")])],1):_vm._e(),_c('div',{staticClass:"h-100"},[(
        Object.keys(_vm.collaborateur).length > 0 &&
          !(_vm.loadingCollab || _vm.loadingData)
      )?_c('div',{staticClass:"d-flex flex-column rounded h-100"},[_c('q-header',{attrs:{"collaborateur":_vm.collaborateur}}),_c('div',{staticClass:"d-flex flex-column flex-md-row h-100"},[_c('left-bar'),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{staticClass:"w-100 pl-2 pr-2 pr-md-3"},[_c('router-view')],1)])],1)],1):_vm._e(),(
        !Object.keys(_vm.collaborateur).length > 0 &&
          !_vm.loadingCollab &&
          !_vm.loadingData
      )?_c('div',{staticClass:"p-3"},[_c('h2',[_vm._v("Aucune donnée")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }