<template>
  <Header>
    <avatar-manager
      v-if="collaborateur.id"
      class="p-0 p-md-2 mr-md-3"
      v-model="collaborateur_avatar"
      :url="'/collaborateurs/' + collaborateur.id + '/avatar'"
      propSize="8rem"
    ></avatar-manager>
    <div class="p-2">
      <header-titre :collaborateur="collaborateur"></header-titre>
      <header-contrats :collaborateur="collaborateur"></header-contrats>
    </div>
    <close-link class="ml-auto mr-3" text="Revenir au tableau de bord" link="/sirh/dashboard"></close-link>
  </Header>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import Header from "@/components/bases/Header.vue";
import AvatarManager from "@/components/application/AvatarManager.vue";
import HeaderContrats from "@/components/collaborateurs/profil/header/HeaderContrats.vue";
import HeaderTitre from "@/components/collaborateurs/profil/header/HeaderTitre.vue";
import CloseLink from "@/components/bases/CloseLink.vue";
export default {
  components: {
    AvatarManager,
    HeaderContrats,
    HeaderTitre,
    Header,
    CloseLink,
  },
  computed: {
    ...mapFields("collaborateurs", ["collaborateur", "collaborateur.collaborateur_avatar"]),
  },
};
</script>
