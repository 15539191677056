<template>
  <menu-left>
    <menu-left-item route="sirh_collaborateur_infos" text="Informations" icon="info"></menu-left-item>
    <menu-left-item route="sirh_collaborateur_coordonnees" text="Coordonnées" icon="phone"></menu-left-item>
    <menu-left-item route="sirh_collaborateur_tasks" text="Tâches" icon="check-square"></menu-left-item>
    <menu-left-item route="sirh_collaborateur_contrats" text="Contrats" icon="file-text"></menu-left-item>
    <menu-left-item route="sirh_collaborateur_exposition" text="Expositions" icon="zap"></menu-left-item>
    <menu-left-item route="sirh_collaborateur_dosimetrie" text="Dosimétrie" icon="shield"></menu-left-item>
    <menu-left-item route="sirh_collaborateur_medical" text="Médical" icon="activity"></menu-left-item>
    <menu-left-item route="sirh_collaborateur_formations" text="Formations" icon="book"></menu-left-item>
    <menu-left-item route="sirh_collaborateur_habilitations" text="Habilitations" icon="bookmark"></menu-left-item>
    <menu-left-item route="sirh_collaborateur_competences" text="Compétences" icon="award"></menu-left-item>
    <menu-left-item route="sirh_collaborateur_entretiens" text="Entretiens" icon="thumbs-up"></menu-left-item>
    <menu-left-item route="sirh_collaborateur_acces" text="Accès" icon="unlock"></menu-left-item>
    <menu-left-item route="sirh_collaborateur_materiels" text="Matériels" icon="box"></menu-left-item>
    <menu-left-item route="sirh_collaborateur_documents" text="Documents" icon="folder"></menu-left-item>
    <menu-left-item route="sirh_collaborateur_divers" text="Divers" icon="star"></menu-left-item>
    <menu-left-item route="sirh_collaborateur_accueil" text="Accueil" icon="coffee"></menu-left-item>
    <menu-left-item route="sirh_collaborateur_objectifs" text="Objectifs" icon="target"></menu-left-item>
    <menu-left-item route="sirh_collaborateur_users" text="Utilisateurs" icon="eye"></menu-left-item>
    <menu-left-item route="sirh_collaborateur_admin" text="Admin" icon="key"></menu-left-item>
  </menu-left>
</template>
<script>
import MenuLeft from "@/components/bases/MenuLeft.vue";
import MenuLeftItem from "@/components/bases/MenuLeftItem.vue";

export default {
  components: { MenuLeft, MenuLeftItem },
};
</script>
<style lang="css"></style>
