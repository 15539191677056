<template>
  <div
    class="d-flex flex-column align-items-center justify-content-center"
    :style="'min-width:'+size+'; min-height:'+size"
  >
    <!-- AVATAR -->
    <label for="avatar" class="cursor-pointer m-0" v-if="!loading" v-tooltip="'Changer d\'image'">
      <AppAvatar :url="value" :propSize="size" ref="avatar"></AppAvatar>
      <input id="avatar" type="file" @change="avatarChange" class="d-none" />
    </label>

    <!-- DOWNLOAD LOADER -->
    <div class="mb-3" v-if="loading">
      <div class="spinner-grow text-primary" role="status" :style="size"></div>
    </div>

    <!-- UPLOAD PROGRESS -->
    <div v-if="loading && progress > 0" class="progress" style="width:100%; height:2px;">
      <div class="progress-bar" v-bind:style="{ width: progress + '%' }" role="progressbar"></div>
    </div>
    <div class="text-center" v-if="loading && progress > 0">
      <small>{{ progress }} %</small>
    </div>

    <!-- ERRORS -->
    <div class="invalid-feedback d-block list-unstyled" v-if="errors.avatar">
      <li v-for="err in errors.avatar" :key="err">{{ err }}</li>
    </div>
  </div>
</template>
<script>
import AppAvatar from "@/components/bases/Avatar.vue";

export default {
  name: "AppAvatarManager",
  components: { AppAvatar },
  data() {
    return {
      avatar: null,
      loading: false,
      progress: 0,
      errors: {}
    };
  },
  props: {
    value: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: "10rem"
    }
  },
  methods: {
    avatarChange(e) {
      this.loading = true;
      this.errors = {};
      this.progress = 0;
      let $_file = e.target.files[0]; // input files
      let formData = new FormData();
      formData.append("file", $_file);

      this.$http
        .post(this.url, formData, {
          onUploadProgress: progressEvent => {
            if (progressEvent.lengthComputable) {
              this.progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
            }
          }
        })
        .then(response => this.$emit("input", response.data.path))
        .catch(error => this.errHandler(error))
        .finally(() => (this.loading = false));
    },

    errHandler: function(error) {
      // LARAVEL VALIDATION ERROR
      if (error.response.status === 422) {
        this.errors = error.response.data.error;
        return;
      }
    }
  }
};
</script>
<style lang="css"></style>
