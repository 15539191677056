<template>
  <div class="d-flex flex-row flex-md-column justify-content-start m-2 m-md-3 p-md-2 mobile-bar">
    <slot></slot>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    id: {
      Type: Number,
      Requierd: true,
      Default: 0
    }
  }
};
</script>
<style lang="css">
@media (max-width: 768px) {
  .mobile-bar {
    max-width: 100%;
    overflow-y: auto;
  }
}
</style>