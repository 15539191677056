<template>
  <bloc-simple class="h-100">
    <div class="d-flex align-items-center" v-if="loadingCollab || loadingData">
      <loader class="m-5"></loader>
      <h2 class="m-0">Chargement...</h2>
    </div>
    <div class="h-100">
      <div
        class="d-flex flex-column rounded h-100"
        v-if="
          Object.keys(collaborateur).length > 0 &&
            !(loadingCollab || loadingData)
        "
      >
        <q-header :collaborateur="collaborateur"></q-header>
        <div class="d-flex flex-column flex-md-row h-100">
          <left-bar></left-bar>
          <transition name="fade" mode="out-in">
            <div class="w-100 pl-2 pr-2 pr-md-3">
              <router-view></router-view>
            </div>
          </transition>
        </div>
      </div>
      <div
        v-if="
          !Object.keys(collaborateur).length > 0 &&
            !loadingCollab &&
            !loadingData
        "
        class="p-3"
      >
        <h2>Aucune donnée</h2>
      </div>
    </div>
  </bloc-simple>
</template>
<script>
import QHeader from "@/components/collaborateurs/profil/header/Header.vue";
import LeftBar from "@/components/collaborateurs/profil/menu/LeftBar.vue";
import Loader from "@/components/bases/Loader.vue";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import BlocSimple from "@/components/containers/ContainerBloc.vue";

export default {
  components: {
    QHeader,
    LeftBar,
    Loader,
    BlocSimple
  },
  data() {
    return {
      loadingCollab: false,
      loadingData: false
    };
  },
  props: {
    id: {
      Type: Number,
      required: true
    }
  },
  methods: {
    ...mapActions({
      getCollaborateur: "collaborateurs/getCollaborateur",
      getData: "collaborateurs/getData",
      loadDosimetrie: "dosimetrie/getDoses"
    })
  },
  watch: {
    id: function() {
      this.loadingCollab = true;
      this.getCollaborateur(this.id)
        .then(() =>
          this.loadDosimetrie({ doses: this.collaborateur_dosimetrie })
        )
        .finally(() => (this.loadingCollab = false));
    }
  },
  mounted() {
    this.loadingCollab = true;
    this.loadingData = true;
    this.getCollaborateur(this.id)
      .then(() => this.loadDosimetrie({ doses: this.collaborateur_dosimetrie }))
      .finally(() => {
        this.loadingCollab = false;
        this.loadingData = false;
      });
    //this.getData().finally(() => (this.loadingData = false));
  },
  computed: {
    ...mapFields("collaborateurs", [
      "collaborateur",
      "collaborateur.collaborateur_dosimetrie"
    ])
  }
};
</script>
<style lang="css"></style>
